import type { NewsType } from '@dreamstack/feature-components'
import {
  AnimateIconTranslationRight,
  BodyText,
  getNewsPath,
  GridWithGaps,
  HeadingH2,
  SimpleMuiButton,
  useAnimateIconTranslationRight,
} from '@dreamstack/feature-components'
import { useLocale, useTranslation } from '@dreamstack/i18n'
import { ArrowRight } from '@dreamstack/icons'
import type { OtherNewsArticleFragment } from '@dreamstack/investors-graphql'
import { motion } from 'framer-motion'
import Image from 'next/legacy/image'
import Link from 'next/link'
import type { FunctionComponent } from 'react'
import tw from 'twin.macro'

interface ArticleProps {
  article: OtherNewsArticleFragment
}
const SnippedWrapper = tw.div`mt-px-8 md:mt-0 col-span-12 md:(col-start-3 col-span-10) lg:(col-start-3 col-span-9)`

export const ArticleSnippet: FunctionComponent<
  React.PropsWithChildren<ArticleProps>
> = ({ article }) => {
  const locale = useLocale()
  const t = useTranslation()

  const href =
    article && article.newsType && article.slug
      ? getNewsPath({
          locale,
          newsType: article.newsType as NewsType,
          slug: article.slug,
        })
      : '#'

  const { onHoverStart, controls } = useAnimateIconTranslationRight()

  const hasImage = !!article.image
  const imageProps = hasImage
    ? {
        src: article.image?.url || '/static/images/fallback_slider_image.jpg',
        alt:
          article.image?.description || 'News Article Image without Alt Text',
      }
    : {
        src: '/static/images/fallback_slider_image.jpg',
        alt: 'News Article Fallback Image',
      }

  return (
    <GridWithGaps tw="grid col-span-full">
      <SnippedWrapper tw="order-2 md:(col-span-6 mr-px-16  order-2) lg:(mr-px-16 col-span-5 order-2)">
        <HeadingH2
          tw="py-px-24 md:(pt-0) hyphens[auto]"
          dangerouslySetInnerHTML={{ __html: article.title ?? '' }}
        />
        <BodyText>{article.previewText}</BodyText>
        {/* TODO: Add margin-top to button directly. Add w-full to button on mobile viewport. */}
        <div tw="mt-px-32 md:mt-px-40 lg:mt-px-48">
          <Link href={href} passHref legacyBehavior>
            <motion.a onHoverStart={onHoverStart}>
              <SimpleMuiButton
                variant="contained"
                color="primary"
                endIcon={
                  <AnimateIconTranslationRight animate={controls}>
                    <ArrowRight />
                  </AnimateIconTranslationRight>
                }
              >
                {t('accentro:continueReading')}
              </SimpleMuiButton>
            </motion.a>
          </Link>
        </div>
      </SnippedWrapper>
      <div tw="aspect-w-3 aspect-h-2 self-center col-span-full order-1 md:(col-span-6 order-2) lg:(col-span-7 order-2)">
        <Image
          tw="object-cover"
          {...imageProps}
          alt={imageProps?.alt ?? ''}
          layout="fill"
        />
      </div>
    </GridWithGaps>
  )
}
