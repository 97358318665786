import type { Slide } from '@dreamstack/feature-components';
import { AccentroSlider } from '@dreamstack/feature-components'
import type { CustomBlockFragment } from '@dreamstack/investors-graphql'
import flatMap from 'lodash/flatMap'
import map from 'lodash/map'
import type { FunctionComponent } from 'react'
import 'twin.macro'
import type { QueryAllBlocksRecursivelyResult } from '../../contentful/QueryAllBlocksRecursivelyResult'
import { ArticleSnippet } from './ArticleSnippet'

export const CurrentPressArticles: FunctionComponent<React.PropsWithChildren<{
  block: QueryAllBlocksRecursivelyResult<CustomBlockFragment>
}>> = ({ block }) => {
  const data = block.customBlockStuff?.currentPressArticles

  const articles = flatMap(data?.newsArticles?.items, (item) =>
    !!item ? [item] : []
  )

  const slides = map(articles, (article, index) => {
    return {
      title: article?.title,
      slide: (
        <div key={index} tw="col-span-full">
          <ArticleSnippet article={article} />
        </div>
      ),
    } as Slide
  })
  return (
    <div>
      <AccentroSlider
        optionType={'CurrentPressArticles'}
        slides={slides}
        useBottomNavigation
      />
    </div>
  )
}
