import mapValues from 'lodash/mapValues'
import { getCityUrlPart } from '../property/routing/getCityUrlPart'

export const newsTypeLabels = {
  en: {
    // WEB
    YourPathToOwningRealEstate: 'Real estate purchase',
    RealEstateAndSociety: 'News',
    InvestingSuccessfully: 'Capital investments',
    EffectiveFinancing: 'Financing',
    HomeAndLiving: 'Home & Living',
    TopCitiesAndNeighbourhoods: 'Cities and Neighbourhoods',
    ManagingRealEstate: 'For Owners',

    // INVESTORS
    AdHoc: 'Ad Hoc',
    PressRelease: 'Press Release',
    PressReview: 'Press Review',
    CorporateNews: 'Corporate News',
    DirectorsDealings: 'Directors Dealings',
    VotingRights: 'Voting Rights',
    HomeOwnershipReport: 'Home Ownership Report',
    HousingCostReport: 'Housing Cost Report',
    FinancialReport: 'Financial Report',
    CompanyPresentation: 'Presentation',
    AnnualReport: 'Annual Report',
  },
  de: {
    // WEB
    YourPathToOwningRealEstate: 'Immobilienkauf',
    RealEstateAndSociety: 'Aktuelles',
    InvestingSuccessfully: 'Kapitalanlagen & Investments',
    EffectiveFinancing: 'Finanzierung',
    HomeAndLiving: 'Wohntrends',
    TopCitiesAndNeighbourhoods: 'Städte und Quartiere',
    ManagingRealEstate: 'Für Eigentümer',

    // INVESTORS
    AdHoc: 'Ad Hoc',
    PressRelease: 'Pressemeldungen',
    PressReview: 'Pressespiegel',
    CorporateNews: 'Corporate News',
    DirectorsDealings: 'Directors Dealings',
    VotingRights: 'Stimmrechte',
    HomeOwnershipReport: 'Wohneigentumsreport',
    HousingCostReport: 'Wohnkostenreport',
    FinancialReport: 'Finanzberichte',
    CompanyPresentation: 'Präsentation',
    AnnualReport: 'Jahresberichte', // TODO: actual translation for AnnualReport
  },
}

export type NewsType = keyof typeof newsTypeLabels['en']

export const NEWS_TYPES_WEB: NewsType[] = [
  'RealEstateAndSociety',
  'YourPathToOwningRealEstate',
  'InvestingSuccessfully',
  'EffectiveFinancing',
  'HomeAndLiving',
  'TopCitiesAndNeighbourhoods',
  'ManagingRealEstate',
]
export const NEWS_TYPES_INVESTORS: NewsType[] = [
  'AdHoc',
  'PressRelease',
  // 'PressReview',
  // 'CorporateNews',
  'DirectorsDealings',
  'VotingRights',
  'HomeOwnershipReport',
  'HousingCostReport',
  'FinancialReport',
  'CompanyPresentation',
  // 'AnnualReport',
]

export const newsTypeUrls = mapValues(newsTypeLabels, (labels) =>
  mapValues(labels, (label) => getCityUrlPart(label))
)

// Overwrites
newsTypeUrls.en.HomeAndLiving = 'home-and-living'
newsTypeUrls.de.InvestingSuccessfully = 'kapitalanlagen-und-investments'

// console.log({ newsTypeLabels, newsTypeUrls })

export const isValidNewsType = (
  locale: 'en' | 'de',
  newsType: string | undefined | null
): newsType is NewsType => {
  if (!locale || !newsType) return false
  const localeLabels: any = newsTypeLabels[locale]
  if (!localeLabels) return false
  const label = localeLabels[newsType]
  if (!label) return false
  return true
}
