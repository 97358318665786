import type {
  IOtherLocaleUrlsContext,
  IOtherLocaleUrlsContextIncomplete,
  LocaleString,
} from '@dreamstack/i18n'
import type { NewsArticleLocaleSlugsFragment } from '@dreamstack/investors-graphql'
import findKey from 'lodash/findKey'
import type { NewsType} from './newsTypeUrls.static';
import { newsTypeUrls } from './newsTypeUrls.static'

export type GetNewsPathOptions = {
  locale: 'en' | 'de'
  newsType: NewsType
  slug: string
}

export const newsBaseUrls: { [key: string]: string } = {
  de: `/${process.env.NEXT_PUBLIC_NEWS_BASE_URL_DE || 'news'}`,
  en: `/${process.env.NEXT_PUBLIC_NEWS_BASE_URL_EN || 'news'}`,
}

export const newsHubOtherLocaleUrls: IOtherLocaleUrlsContext = {
  de: { url: newsBaseUrls.de },
  en: { url: newsBaseUrls.en },
}

export const getNewsPath = ({ locale, newsType, slug }: GetNewsPathOptions) => {
  const baseUrl = newsBaseUrls[locale]
  const newsTypeUrl = getNewsTypeUrlPart({ newsType, locale })
  const path = `${baseUrl}/${newsTypeUrl}/${slug}`
  return path
}

export const getNewsPathOtherLocales = (
  article: NewsArticleLocaleSlugsFragment
) => {
  const deExists = true

  // TODO: find out if there is no en version more elegantly/bulletproof
  const enExists =
    article?.titleDe !== article.titleEn || article?.slugEn !== article?.slugDe

  const otherLocaleUrls: IOtherLocaleUrlsContextIncomplete = {
    de:
      deExists && article.newsType && article.slugDe
        ? {
            url: getNewsPath({
              locale: 'de',
              newsType: article.newsType as NewsType,
              slug: article.slugDe,
            }),
          }
        : null,
    en:
      enExists && article.newsType && article.slugEn
        ? {
            url: getNewsPath({
              locale: 'en',
              newsType: article.newsType as NewsType,
              slug: article.slugEn,
            }),
          }
        : null,
  }
  return otherLocaleUrls
}

export const getNewsTypeUrlPart = ({
  newsType,
  locale,
}: Pick<GetNewsPathOptions, 'newsType' | 'locale'>) => {
  return newsTypeUrls[locale][newsType] as string
}

export const getNewsTypeUrlPartReverse = ({
  newsTypeUrl,
  locale,
}: {
  newsTypeUrl: string
  locale: LocaleString
}) => {
  const urlMappings = newsTypeUrls[locale]
  const newsType = findKey(urlMappings, (url) => url === newsTypeUrl)
  return newsType
}
